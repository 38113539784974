import { HttpClient } from "@angular/common/http";
import { Injectable, Output,EventEmitter } from "@angular/core";
import { WorkItem } from "../models/workitem.model";
import { Comment } from "../models/comment.model";
import { GlobalConstants } from "../config/global-constants";

@Injectable({ providedIn: "root" })

export class RequirementDetailsService {

  @Output() reqData = new EventEmitter<{}>();
  @Output() selected = new EventEmitter<{}>();

  newmessage = '';
  requirement = {};
  documents = {};
  workItems = {};
  children = {};
  constructor(private http: HttpClient) {
  }

  getRequirementData(id: string) {
    const queryParams = `?workitemId=${id}`;
    return this.http
      .get<{}>(
        GlobalConstants.api_redirect_uri + '/requirements/api/requirement' + queryParams
      ).subscribe({
        next: (data: any) => {
          this.reqData.emit(data)
        }, error:error => {
          this.reqData.emit(new Error('No data received'))
        }
      })
  }

  getRequirementDocuments(documentsPerPage: number, currentPage: number) {
    const queryParams = `?pagesize=${documentsPerPage}&page=${currentPage}`;
    return this.http
      .get<{ documents: any }>(
        GlobalConstants.api_redirect_uri + '/requirements/api/documents' + queryParams
      )
  }
  getWorkItems(requirementPerPage: number, currentPage: number, docname: string) {
    const queryParams = `?pagesize=${requirementPerPage}&page=${currentPage}&doc=${docname}`;
    return this.http
      .get<{ workItems: any }>(
        GlobalConstants.api_redirect_uri + '/requirements/api/workitems/document' + queryParams
      )
  }
  getWorkItemChildren(workitem: string) {
    const queryParams = `?workitem=${workitem}`;
    return this.http
      .get<[children: any]>(
        GlobalConstants.api_redirect_uri + '/requirements/api/workitems/children' + queryParams
      )
  }

  addWorkItem(obj: WorkItem) {
    this.http
      .post<any>(GlobalConstants.api_redirect_uri + "/requirements/api/new", obj).subscribe(responseData => {
        console.log(responseData)
      })
  }

  workItemCheck(obj: WorkItem){
    const queryParams = `?workitem_id=${obj.workitem_id}`;
    return this.http
      .get<{ message: any }>(
        GlobalConstants.api_redirect_uri + '/requirements/api/workitems/checks' + queryParams
      )
  }

  workItemParent(document: any){
    const queryParams = `?document=${document}`;
    return this.http
      .get<[children: any]>(
        GlobalConstants.api_redirect_uri + '/requirements/api/workitems/parent' + queryParams
      )
  }

  postComment(obj: Comment, workitem: string): any {
    const queryParams = `?workitem_id=${workitem}`;
    return this.http
      .post<any>(GlobalConstants.api_redirect_uri + "/requirements/api/comments/new" + queryParams, obj)
  }
  
  updateComment(obj: Comment){
    this.http
      .put<any>(GlobalConstants.api_redirect_uri + "/requirements/api/comments/update", obj).subscribe(responseData => {
        console.log(responseData);
      })
  }

  deleteComment(obj, workitem: string) {
    const queryParams = `?workitem_id=${workitem}`;
    this.http
      .delete<any>(GlobalConstants.api_redirect_uri + "/requirements/api/comments/delete" + queryParams, obj).subscribe(responseData => {
        console.log(responseData);
      })
  }

  selectWorkitemFromTree(node){
    this.selected.emit(node);
  }
}