import { Component, OnInit } from '@angular/core';
import { RequirementDetailsService } from 'src/app/services/requirement.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorService } from 'ia-shared';
import { Subscription } from "rxjs"

@Component({
    selector: 'app-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.scss'],
})

export class TreeComponent implements OnInit {
    //document level
    documentPerPage = 5;
    currentPage = 1;
    total: number;
    items: any[] = [];
    expandDocument = false;
    size: string;

    //workitem level
    workitemPerPage = 8;
    currentworkItemPage = 1;
    totalParentWorkitems: number;
    workitems: any[] = [];
    children: any[] = [];
    expandWorkitem: Array<boolean>;

     standards = [
        {name: 'Casing and Formation Integrity Testing', id: 'A'},
        {name: 'Basis of Design', id: 'B'},
      ];
      businessunits = [
        {name: 'GOM', id: 'A'},
        {name: 'MCBU', id: 'B'},
        {name: 'RBU', id: 'C'},
      ];
      domain = [ {name: 'Wells', id: 'A'},
      {name: 'Surface', id: 'B'},
      {name: 'HSE', id: 'C'},]
    //error detecting
    message = '';
    private errorSub: Subscription;

    constructor(private requirementService: RequirementDetailsService, private spinner: NgxSpinnerService, private errorService: ErrorService) { }

    ngOnInit() {
        this.spinner.show('sp-tree');
        this.requirementService.getRequirementDocuments(this.documentPerPage, this.currentPage).subscribe({
            next: (data: any) => {
                this.items = data[0].data;
                this.total = data[0].maxCount[0].count;
                this.spinner.hide('sp-tree');
            }, error: error => {
                this.spinner.hide('sp-tree')
                this.errorSub = this.errorService.getErrorListener().subscribe(message => {
                });
                this.message = 'Something went wrong. Please refresh the page and try again.'
            }
        })
        this.spinner.hide('sp-tree');
    }
    pageChanged(event) {
        this.spinner.show('sp-tree');
        this.currentPage = event;
        this.requirementService.getRequirementDocuments(this.documentPerPage, this.currentPage).subscribe(data => {
            this.items = data[0].data;
        })
        this.spinner.hide('sp-tree');
    }

    workItemsPageChanged(event) {
        this.spinner.show('sp-tree');
        const result = this.items.find(element => element.selected == 'true');
        const docname = result.document;
        this.currentworkItemPage = event;
        this.getWorkitems(event, docname);
        this.spinner.hide('sp-tree');
    }

    toggleExpand(document) {
        this.spinner.show('sp-tree');
        this.expandDocument = !this.expandDocument;
        this.getWorkitems(this.currentworkItemPage, document);
        //get the work items for the selected document if expanded
        if (this.expandDocument) {
            this.select(this.items, "document", document);
        } 
        else {
            this.deSelect(this.items, 'true')
            this.currentworkItemPage = 1;
        }
        this.spinner.hide('sp-tree');
    }

    select(items: any, parameter: string, selected: string,){
        for (let i = 0; i < items.length; i++) {
            let doc = items[i][parameter];
            if (doc == selected) {
                items[i].selected = 'true';
            } else {
                items[i].selected = 'false';
            }
        }
    }

    deSelect(items: any, selector: string){
        for (let i = 0; i < items.length; i++) {
            items[i].selected = selector;
        }
    }

    getWorkitems(page, document) {
        this.requirementService.getWorkItems(this.workitemPerPage, page, document).subscribe(data => {
            this.workitems = data[0].data;
            this.totalParentWorkitems = data[0].maxCount[0].count;
            this.expandWorkitem = new Array(this.workitems.length).fill(false);
        })
    }
    
    selectedWorkitem(workitem: any, index){
        //deselect the child workitem if any and select the parent workitem
       
        if (workitem.parent){
            if (this.children != undefined || this.children.length != 0) {
                for (let child in this.children){
                    for (let i=0; i<child.length; i++){
                        this.deSelect(this.children[child], 'false');
                    }
                }
            }
            this.select(this.workitems, "workitem_id", workitem.id);
        }
        //deselect the parent and child workitem if any, and select the child workitem
        else {
            if (this.workitems != undefined || this.workitems.length > 0){
                this.deSelect(this.workitems, 'false');
            }
            if (this.children != undefined || this.children.length != 0) {
                for (let child in this.children){
                    for (let i=0; i<child.length; i++){
                        this.deSelect(this.children[child], 'false');
                    }
                }
            }
            for (let child in this.children){
                for (let i=0; i<child.length; i++){
                    this.select(this.children[index], "workitem_id", workitem.id);
                }
            }         
        }
    }
}



