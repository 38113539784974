import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RequirementDetailsService } from 'src/app/services/requirement.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: 'app-workitem',
    templateUrl: './workitem.component.html',
    styleUrls: ['./workitem.component.scss'],
})

export class WorkItemComponent {
    @Input() workitems: any[];
    @Input() workitem;
    @Input() expandWorkitem: Array<boolean>;
    @Input() index;
    @Input() children: any[];
    @Output() selected: EventEmitter<any> = new EventEmitter();

    constructor(private requirementService: RequirementDetailsService, private spinner: NgxSpinnerService) { }

    onSelected() {
        /* 
        Emit the workitem details (id and parent status) to other services.
        */
        this.requirementService.selectWorkitemFromTree({ id: this.workitem.workitem_id });
        this.selected.emit({ id: this.workitem.workitem_id, parent: this.workitem.parent });
    }

    toggleChild() {
        /*
        Fetch the childern data of the selected workitem using the requirement service. 
        */
        this.spinner.show('sp-workitem');
        this.expandWorkitem[this.index] = !this.expandWorkitem[this.index];
        this.requirementService.getWorkItemChildren(this.workitems[this.index].workitem_id).subscribe(data => {
            this.children[this.index] = data[0].data;
        })
        this.spinner.hide('sp-workitem');
    }
}


