<div class="req">
    <div class="req__tab-panel">
        <div class="tab tab__lg tab__tree tab--active" (click)="toggleTab('tree')" [ngClass]="{'tab--active': tab=='tree'}">
            <svg class='icon-basic'>
                <use xlink:href="/app/assets/icons/sprite.svg#tree"></use>
            </svg>
        </div>
        <div class="tab tab__lg tab__graph" (click)="toggleTab('graph')" [ngClass]="{'tab--active': tab=='graph'}">
            <svg class='icon-basic'>
                <use xlink:href="/app/assets/icons/sprite.svg#graph"></use>
            </svg>
        </div>
    </div>
      
    <div class="req__body">
        <app-tree *ngIf="tab=='tree'" class="tree"></app-tree>
        <app-graph *ngIf="tab=='graph'" class="graph"></app-graph>
    </div>
    <div class="req__details--tabs">
        <div class="tab__details tab--active" (click)="toggleReqTab('reqdetails')" [ngClass]="{'tab--active': reqtab=='reqdetails'}">
            <svg class='icon-basic'>
                <use xlink:href="/app/assets/icons/sprite.svg#details"></use>
            </svg>
        </div>
        <div class="tab__comments tab--active" (click)="toggleReqTab('comments')" [ngClass]="{'tab--active': reqtab=='comments'}">
            <svg class='icon-basic'>
                <use xlink:href="/app/assets/icons/sprite.svg#comment"></use>
            </svg>
        </div>
        <div class="tab__changelog tab--active" (click)="toggleReqTab('changelog')" [ngClass]="{'tab--active': reqtab=='changelog'}">
            <svg class='icon-basic'>
                <use xlink:href="/app/assets/icons/sprite.svg#changelog"></use>
            </svg>
        </div>
    </div>
    <div class="req__details">
        <app-requirement-details *ngIf="reqtab=='reqdetails'" calss="requirement-details"></app-requirement-details>
        <app-comments *ngIf="reqtab=='comments'" class="comments" [comments]="comments"
        [comments_exist]="comments_exist"
        [comments_number]="comments_number"
        (commentAdded)="addComment($event)"
        (commentDeleted)="deleteComment($event)"></app-comments>
        <app-changeLog *ngIf="reqtab=='changelog'" [change_logs]="timeline"></app-changeLog>
    </div>  
</div>
