import { Component, OnInit, AfterViewInit, HostListener, ElementRef } from '@angular/core';
import { GraphService } from 'src/app/services/graph.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorService } from 'ia-shared';
import { Subscription } from "rxjs"

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
})
export class GraphComponent implements AfterViewInit, OnInit {
  colorBy: string = 'BU'
  message = '';
  node = 0;
  edge = 0;


  selectedStandard = [];
  selectedOperation = [];
  selectedEquipment = [];

  standards = [
    {name: 'Casing and Formation Integrity Testing', id: 'A'},
    {name: 'Basis of Design', id: 'B'},
  ];
  businessunits = [
    {name: 'GOM', id: 'A'},
    {name: 'MCBU', id: 'B'},
    {name: 'RBU', id: 'C'},
  ];
  domain = [ {name: 'Wells', id: 'A'},
  {name: 'Surface', id: 'B'},
  {name: 'HSE', id: 'C'},]
  private errorSub: Subscription;
  constructor(private elementRef: ElementRef, public graphService: GraphService, private spinner: NgxSpinnerService, private errorService: ErrorService) { }

  ngOnInit(): void {
    this.spinner.show('sp-graph');
    //Fetch the lists from the data base 

  }

  ngAfterViewInit(): void {
    this.getData(this.selectedStandard, this.selectedOperation, this.selectedEquipment)
  };

  getData(document: string[], operation: string[], equipment: string[]) {
    this.graphService.getGraphData(document, operation, equipment).subscribe({
      next: (data: any) => {
        this.graphService.onGraph('bu', data)
        this.spinner.hide('sp-graph')
        //get the number of nodes 
        this.node = data.nodes.length;
        //get the number of eadges 
        this.edge = data.links.length;
      }, error: error => {
        this.spinner.hide('sp-graph')
        this.errorSub = this.errorService.getErrorListener().subscribe(message => {
        });
        this.message = 'Something went wrong. Please refresh the page and try again.'
      }
    })
  }

  onClickColorBy(item: string) {
    this.graphService.coloredByGraph(item)
    this.colorBy = this.graphService.colorBy;
  }

  @HostListener("window:resize")
  public windowResize(): void {
    const widthDiv = document.getElementById('graph').offsetWidth;
    this.graphService.graph.width(widthDiv);
  }

  private get htmlElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  selected(event: any, string: string) {
    //update the select lists
    if (string == 'standard') {
      this.selectedStandard = event;
    } else if (string == 'operation') {
      this.selectedOperation = event;
    } else if (string == 'equipment') {
      this.selectedEquipment = event;
    }

    //Request data from the server
    this.spinner.show('sp-graph');
    this.getData(this.selectedStandard,this.selectedOperation,this.selectedEquipment)
  }

}
