import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownMenuComponent implements OnChanges {
  @Input() options: string[] = [];
  @Input() selectedValue: string | string[] | undefined;
  @Input() size: string | undefined;
  @Input() secondaryColor: boolean;
  @Input() isLoading: boolean;
  @Input() multi: boolean = false;
  @Output() selectionChange: EventEmitter<string | string[]> = new EventEmitter<string | string[]>();
  @Output() dropdownOpen: EventEmitter<void> = new EventEmitter<void>();

  searchValue: string = '';
  selectedItems: BehaviorSubject<string | string[] | null> = new BehaviorSubject<string | string[]>([]);
  isDropdownOpen: boolean = false;
  constructor(private spinner: NgxSpinnerService){}
  ngOnChanges(changes: SimpleChanges): void {
    changes.isLoading? this.spinner.show("sp-dropdown"): this.spinner.hide("sp-dropdown");
    if (changes.selectedValue) {
      const selectedValue = changes.selectedValue.currentValue;

      if (Array.isArray(selectedValue)) {
        this.selectedItems.next([...selectedValue]);
      } else {
        this.selectedItems.next(selectedValue)
      }
    }
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.dropdownOpen.emit();
    }
  }

  selectOption(option: string): void {
    if (this.multi) {
      // Multi-selection
      const currentItems = this.selectedItems.getValue();
      if (Array.isArray(currentItems)) {
        if (this.isSelected(option)) {
          this.selectedItems.next(currentItems.filter(item => item !== option));
        } else {
          currentItems.push(option);
          this.selectedItems.next(currentItems);
        }
      } else {
        this.selectedItems.next([option]);
      }
    } else {
      // Single selection
      this.selectedItems.next(option);
    }
    this.selectionChange.emit(this.selectedItems.getValue());
    this.isDropdownOpen = false;
  }

  isSelected(option: string): boolean {
    const currentItems = this.selectedItems.getValue();

    if (Array.isArray(currentItems)) {
      return currentItems.includes(option);
    } else {
      return currentItems === option;
    }
  }

  filterOptions(): string[] {
    if (this.searchValue) {
      return this.options.filter(option => option.toLowerCase().includes(this.searchValue.toLowerCase()));
    }
    return this.options;
  }
}
