import { GraphComponent } from '../layout/graph/graph.component';
import { WorkItemComponent } from '../components/workitem/workitem.component';
import { StandardComponent } from '../components/standard/standard.component';
import { RequirementDetailsComponent } from '../layout/requirement-details/requirement-details.component';
import { TreeComponent } from '../layout/tree/tree.component';
import { FlowChartComponent } from '../components/flowchart/flowchart.component';
import { GraphService } from '../services/graph.service';
import { RequirementDetailsService } from '../services/requirement.service';
import { RequirementsComponent } from '../page/requirements.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from 'ia-shared';

@NgModule({
  declarations: [
    RequirementsComponent,
   GraphComponent,
   TreeComponent,
   WorkItemComponent,
   StandardComponent,
   RequirementDetailsComponent,
   FlowChartComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SharedComponentsModule
  ],
  exports:[RequirementsComponent],
  providers: [GraphService,RequirementDetailsService ]
})
export class RequirementsModule { }
