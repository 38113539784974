import { Component, Input, OnInit } from '@angular/core';

import { ModalService } from 'ia-shared';
import { GraphService } from 'src/app/services/graph.service';
import { RequirementDetailsService } from 'src/app/services/requirement.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-requirement-details',
  templateUrl: './requirement-details.component.html',
  styleUrls: ['./requirement-details.component.scss'],
})
export class RequirementDetailsComponent implements OnInit{

  ngOnInit(): void {
    let node = {id: 'WELL-233081'}
    this.onSelected(node)
  }

  objectData: any;
  exist = true;  
  @Input() selected: string = ''
  nodeId: string;
  language: string;
  status: string;
  content: string ='Placeholder text';
  standard: string;
  bu: string;
  discipline: string;
  label: string;
  priority: string = '';
  operation: string = '';
  document: string = '';
  equipment: string;
  outcome: string;

  //error detecting
  message = '';

  flowchart = [
    'flowchart TB',
    'A(Drilling):::input--> C(WELL-233147):::req',
    'G(InfT):::input  -->|OR| C(WELL-233147)',
    'B(FIT):::input -->|OR| C(WELL-233147)',
    'D(LOT):::input -->|OR| C(WELL-233147)',
    'C(WELL-233147) -->|Outcome| F[/Dynamic FIT/LOT/InfT Document/]:::outcome',
    'classDef input fill:#2050ee, stroke:#2050ee, stroke-width:1px, color:#ffffffbd',
    'classDef req fill:#6a0dad, stroke:#6a0dad, stroke-width:1px, color:#ffffffbd',
    'classDef outcome fill:#bd4e26, stroke:#bd4e26, stroke-width:1px, color:#ffffffbd',
    'linkStyle 0,1,2,3,4 stroke:#005e00,stroke-width:2px'
  ]

  constructor(
    private graphService: GraphService,
    public requirementService: RequirementDetailsService,
    private spinner: NgxSpinnerService,
    public modalService: ModalService
    ){
    this.graphService.selected.subscribe(
      (node) => this.onSelected(node)
    );
    this.requirementService.selected.subscribe(
      (workitem) => this.onSelected(workitem)
    );
  }



  onSelected(node) {
    this.spinner.show('sp-reqdetilas');
    this.requirementService.getRequirementData(node.id)
    this.requirementService.reqData.subscribe((data: any) => {
      if (data instanceof Error){
        this.message = 'Something went wrong. Please refresh the page and try again.';
        this.exist = false;
      }
      else {
        this.objectData = data;
        let isArr = data instanceof Array;
        //if details found for the work item 
        if (isArr) {
          this.updateArr(data);
          if(data[0].operation.length > 0){
            this.operation = data[0].operation.join(',')
          } else {
            this.operation = 'N/A'
          }
          if (data[0].document.length > 0) {
            this.document = data[0].document.join(',');
          } else {
            this.document = 'N/A'
          }
          if (data[0].equipment.length > 0) {
            this.equipment = data[0].equipment[0].name;
          } else
            this.equipment = 'N/A'
          if (data[0].outcome.length > 0) {
            this.outcome = data[0].outcome[0].outcome_type;
          } else {
            this.outcome = 'N/A'
          }
        } 
      }
      
      this.spinner.hide('sp-reqdetilas');
    })
  }

  updateArr(data):void {
    this.exist = true;
    this.flowchart = data[0].flow_chart;
    this.nodeId = data[0].workitem_id;
    this.language = data[0].language;
    this.status = data[0].status;
    this.content = data[0].content;
    this.standard = data[0].standard;
    this.bu = data[0].bu;
    this.discipline = data[0].discipline;
    this.label = data[0].workitem_label;
    this.priority = data[0].priority;
  }
}