<div class="dropdown-menu" [ngClass]="size" (clickOutside)="isDropdownOpen = false">
  <div class="dropdown-header" [ngClass]="{'dropdown-header__secondary': secondaryColor}" (click)="toggleDropdown()">
    <span class="selected-items">{{ selectedItems | async}}</span>
    <span class="dropdown-icon" [class.open]="isDropdownOpen"></span>
  </div>
  <div class="dropdown-list" [ngClass]="{'dropdown-list__secondary': secondaryColor}" *ngIf="isDropdownOpen">
    <input
      type="text"
      class="search-input"
      placeholder="Search"
      [(ngModel)]="searchValue"
      *ngIf="options && options.length > 5"
    />
    <div class="spinner__container" *ngIf="isLoading" >
      <ngx-spinner name="sp-dropdown" bdColor="transperant" size="small" color="#fff" type="square-jelly-box"
          class="spinner__position" [fullScreen]="false">
          <p style="color: white"> Loading... </p>
      </ngx-spinner>
   </div>

    <ul class="options-list" *ngIf="!isLoading">
      <li
        *ngFor="let option of filterOptions()"
        (click)="selectOption(option)"
        [class.selected]="isSelected(option)"
      >
        {{ option }}
      </li>
    </ul>
  </div>
</div>
