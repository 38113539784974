<div class="document__content">
    <div class="document__header">
        <div class="document__title">
            <span class="txt__title__md">{{item.document}}</span>
        </div>
        <div class="document__icon">
            <ng-container *ngIf="item.status == 'Pending'">
                <svg class='icon--large icon--invalid'>
                    <use xlink:href="/app/assets/icons/sprite.svg#invalid"></use>
                </svg>
            </ng-container>
            <ng-container *ngIf="item.status != 'Pending'">
                <svg class='icon--large icon--valid'>
                    <use xlink:href="/app/assets/icons/sprite.svg#valid"></use>
                </svg>
            </ng-container>
        </div>
        <div class="document__status">
            <span [ngClass]="item.status == 'Pending' ? 'border__pending txt__title__md': 'border__published txt__title__md' ">{{item.status}}</span>
        </div>

    </div>
    <div class="document__footer">
        <div class="document__footer__stats">
            <div class="document__footer__total">
                <span class="txt__title__sm">Total :  </span>
                <span class="txt__title__lg">{{item.total}}</span>
            </div>
            <div class="document__footer__pending">
                <span class="txt__title__sm">Pending :  </span>
                <span class="txt__title__lg color__pending">{{item.pending}}</span>
            </div>
            <div class="document__footer__approved">
                <span class="txt__title__sm">Approved :  </span>
                <span class="txt__title__lg color__approved">{{item.published}}</span>
            </div>
        </div>
        <div class="document__footer--actions">
            <a>
                <svg class='icon-medium'>
                    <use xlink:href="/app/assets/icons/sprite.svg#add"></use>
                </svg>
            </a>
            <a>
                <svg class='icon-medium'>
                    <use xlink:href="/app/assets/icons/sprite.svg#download"></use>
                </svg>
            </a>
            <a>
                <svg class='icon-medium'>
                    <use xlink:href="/app/assets/icons/sprite.svg#details"></use>
                </svg>
            </a>
            <a>
                <svg class='icon-medium'>
                    <use xlink:href="/app/assets/icons/sprite.svg#changelog"></use>
                </svg>
            </a>
        </div>
    </div>
</div>