import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from 'ia-shared';
import { RequirementsModule } from './modules/requirements.module';
import { CalAngularModule } from '@cvx/cal-angular';
import { GlobalConstants } from './config/global-constants';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    RequirementsModule,
    HttpClientModule,
    CalAngularModule.forRoot({
      "autoSignIn": true,
      "popupForLogin": false,
      "cacheLocation": "localStorage",
      "instance": "https://login.microsoftonline.com/",
      "tenantId": "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
      "clientId": GlobalConstants.client_id,
      "redirectUri": GlobalConstants.app_redirect_uri,
      "oidcScopes": ["openid", "profile", "User.Read","offline_access"],
      "graphScopes": ["User.Read", "User.Read.All"]
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
