import { Component, OnInit } from '@angular/core';
import { ModalService } from 'ia-shared';
import { GraphService } from '../services/graph.service';
import { NgxSpinnerService } from "ngx-spinner";
import { RequirementDetailsService } from '../services/requirement.service';


@Component({
  selector: 'app-requirement',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.scss'],
  providers: [GraphService],
})
export class RequirementsComponent implements OnInit {
  reqtab = 'reqdetails'
  tab = 'tree'
  ngOnInit(): void {
    let node = {id: 'WELL-233081'}
    this.onSelected(node)
    this.reqtab = 'reqdetails'
    this.tab = 'tree'
  }
  comments: [{body: string, created_date: string, author: string, cai: string}];
  comments_number: number;
  comments_exist = false;
  message = '';
  exist = true; 
  nodeId: string;
  objectData: any;
  timeline = [];
  constructor(public modalService: ModalService, private graphService: GraphService,public requirementService: RequirementDetailsService,
    private spinner: NgxSpinnerService,){
      this.graphService.selected.subscribe(
        (node) => this.onSelected(node)
      );
      this.requirementService.selected.subscribe(
        (workitem) => this.onSelected(workitem)
      );
    }
  
  toggleTab(tab: string) {
    this.tab = tab;
  }


  toggleReqTab(reqtab: string){
    this.reqtab = reqtab
  }

  onSelected(node, addComment: boolean = false) {
    this.spinner.show('sp-reqdetilas');
    this.requirementService.getRequirementData(node.id)
    this.requirementService.reqData.subscribe((data: any) => {
      if (data instanceof Error){
        this.message = 'Something went wrong. Please refresh the page and try again.';
        this.exist = false;
      }
      else {
        this.objectData = data;
        let isArr = data instanceof Array;
        //if details found for the work item 
        if (isArr) {
          this.exist = true;
          
          this.comments = data[0].comments;
          this.timeline = data[0].change_log;
          this.comments_number = this.comments.length;
          if(this.comments_number > 0){
            this.comments_exist = true;
          }
          else {
            this.comments_exist = false;
          }
        } else {
          this.message = 'NO DETAILS WERE FOUND FOR THIS ITEM.';
          this.exist = false;
        }
      }
      
      this.spinner.hide('sp-reqdetilas');
    })
    if(!addComment){
      this.toggleReqTab('details');
    }
  }

  addComment(comment: any){
    if(this.comments.length < 1){
      let node = {id: this.nodeId}
      this.onSelected(node, true);
    }
    else{
      this.comments.push(comment);
    }
  }
  deleteComment(i: any){
    this.comments.splice(i, 1);
    if (this.comments.length < 1){
      this.comments_exist = false;
    }
  }
}


