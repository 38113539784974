import { Component, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { GraphService } from 'src/app/services/graph.service';
import { RequirementDetailsService } from 'src/app/services/requirement.service';

import mermaid from 'mermaid';

@Component({
  selector: 'app-flowchart',
  templateUrl: './flowchart.component.html',
  styleUrls: ['./flowchart.component.scss']
})
export class FlowChartComponent implements AfterViewInit {
  result = '';
 @Input() flowChart;
 
  constructor(private graphService: GraphService, private requirementService: RequirementDetailsService) {

    this.graphService.selected.subscribe(
      (data: any) => {
       this.requirementService.reqData.subscribe((result: any) => {
        let isArr = result instanceof Array;
        if(isArr){
          this.flowChart = result[0].flow_chart;
          this.onSelected(this.flowChart)
        }
       })
      }
    );

  }

  @ViewChild('mermaidDiv') mermaidDiv: ElementRef;

  public ngAfterViewInit(): void {
    const data = this.flowChart
    if(data){
      this.mermaidStart(data);
    }
    
  }

  mermaidStart(data) {
    this.result = data.join("\n")
    mermaid.initialize({
      theme: "default"
    });

    const element: any = this.mermaidDiv.nativeElement;
    const graphDefinition = `${this.result}`
    mermaid.render("graphDiv", graphDefinition, (svgCode, bindFunctions) => {
      element.innerHTML = svgCode;
      bindFunctions(element);
    });
  }
  onSelected(data) {
    this.mermaidStart(data)
  }
}