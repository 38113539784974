import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
 
@Component({
    selector: 'app-usermessage-dialog',
    templateUrl: './userMessage-dialog.component.html',
    styleUrls: ['./userMessage-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UserMessageDialog {
    @Input() message: string = "Successfull!";
    @Input() showDialog: boolean;
    @Output() showDialogChange = new EventEmitter<boolean>();
 
    onConfirmClick(): void {
        this.showDialog = false;
        this.showDialogChange.emit(this.showDialog);
    }
}