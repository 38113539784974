import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DropdownMenuComponent } from '../components/dropdown-menu/dropdown-menu.component';
import { ConfirmationDialog } from '../components/confirmation-dialog/confirmation-dialog.component';
import { ModalComponent } from '../components/modal/modal.component';
import { TextShortenPipe } from '../pipes/textshorten.pipe';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { RouterLinkDirectiveStub } from '../directives/routerlink-stub.directive';
import { UserMessageDialog } from '../components/userMessage-dialog/userMessage-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgxSpinnerModule
    ],
    declarations: [
        DropdownMenuComponent,
        UserMessageDialog,
        ConfirmationDialog,
        ModalComponent,
        TextShortenPipe,
        ClickOutsideDirective,
        RouterLinkDirectiveStub
    ],
    exports: [
        DropdownMenuComponent,
        UserMessageDialog,
        ConfirmationDialog,
        ModalComponent, 
        TextShortenPipe,
        ClickOutsideDirective,
        RouterLinkDirectiveStub
    ]
})
export class SharedComponentsModule { }