<div class="graph">
    <div class="graph__control-panel">
        <app-dropdown-menu title="Domain" [options]="domain" [multi]="true"></app-dropdown-menu>
        <app-dropdown-menu title="Standard" [options]="standards" [multi]="true"></app-dropdown-menu>
        <app-dropdown-menu title="BU" [options]="businessunits" [multi]="true"></app-dropdown-menu>
    </div>
    <div id="graph" class="graph__container">
        <ngx-spinner name="sp-graph" bdColor="transperant" size="medium" color="#fff" type="square-jelly-box"
            [fullScreen]="false" class="spinner">
            <p style="color: white"> Loading... </p>
        </ngx-spinner>
    </div>
    <div class="graph__metrics">
        <div class="graph__metrics__container">
            <p class="txt__title__md">
                <span class="txt__value__lg mgl-1rem txt__metric">2</span> Standards
                <span class="txt__value__lg mgl-1rem txt__metric">809</span> Work-items
            </p>
        </div>
        <div class="graph__metrics__settings">
        </div>
        <div class="graph__metrics__nodes">
            <p class="txt__title__md">
                <span class="txt__value__lg mgl-1rem txt__metric">185</span> Nodes
                <span class="txt__value__lg mgl-1rem txt__metric">1,643</span> Edges
            </p>
        </div>
    </div>
</div>

<div class="message--nodata">
    <p>{{message}}</p>
</div>