<div class="dialog" *ngIf="showDialog">
    <div class="dialog__container">
        <div class="dialog__content">
            <span class="txt__title__lg">{{message}}</span>
            <div class="dialog__buttons">
                <button class="btn btn__md btn__modify" (click)="onCancelClick()">{{cancelButtonText}}</button>
                <button class="btn btn__md btn__submit" (click)="onConfirmClick()">{{confirmButtonText}}</button> 
            </div>
        </div>
    </div>
</div>