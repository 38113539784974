<div class="workitem-parent"
    [ngClass]="{'workitem--pending': workitem.status == 'Pending',
        'workitem--rejected': workitem.status == 'Rejected',
        'workitem--selected': workitem.selected == 'true'}"
    (click)="onSelected()">
    <ng-container *ngIf="workitem.children?.length > 0">
        <div class="workitem-parent__expand" [ngClass]="{'expanded': expandWorkitem[index]}" (click)="toggleChild()">
            <svg class='icon-basic'>
                <use xlink:href="/app/assets/icons/sprite.svg#expand"></use>
            </svg>
        </div>
    </ng-container>     
    <div class="workitem__item__id">
        <span class="txt__title__md">{{workitem.workitem_id}}</span>
    </div>
    <div class="workitem__item__content">
        <span class="txt__title__md">{{workitem.content | textshorten:100}}</span>
    </div>
    <div class="workitem__item__actions">
        <svg class='icon__md'>
            <use xlink:href="/app/assets/icons/sprite.svg#edit"></use>
        </svg>
        <svg class='icon__md'>
            <use xlink:href="/app/assets/icons/sprite.svg#share"></use>
        </svg>
        <svg class='icon__md'>
            <use xlink:href="/app/assets/icons/sprite.svg#delete"></use>
        </svg>
    </div>
</div>
<ngx-spinner name="sp-workitem" bdColor="transperant" size="medium" color="#fff" type="square-jelly-box"
    [fullScreen]="false" class="spinner">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
