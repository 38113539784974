import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { CalAngularService } from '@cvx/cal-angular';

@Injectable({ providedIn: "root" })
export class HttpInterceptorService implements HttpInterceptor {
    constructor(private authService : CalAngularService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Attaching id token to the header of 'Authorization'
        const scopes = this.getScopes(req.url);
        return this.authService.getAADToken(scopes).pipe(
            switchMap(token => {
                req = req.clone({
                    setHeaders: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                return next.handle(req);
            }
        ))
    }

    private getScopes(url: string): string[] {
        if (url.startsWith("https://graph.microsoft.com")) {
            return ["https://graph.microsoft.com/User.Read"];
        }
        if (url.startsWith("https://integrated-assurance") && url.includes("dev")) {
            return ["https://integrated-assurance-test.azure.chevron.com/user_impersonation"];
        }
        if (url.startsWith("https://integrated-assurance") && url.includes("test")) {
            return ["https://integrated-assurance-test.azure.chevron.com/user_impersonation"];
        }
        if (url.startsWith("https://integrated-assurance") && url.includes("prod1")) {
            return ["https://integrated-assurance-prod1.azure.chevron.com/user_impersonation"];
        }
        else {
            return [];
        }
    }
}