import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit, EventEmitter, Output } from "@angular/core";
import ForceGraph, { ForceGraphInstance } from 'force-graph';
import { GlobalConstants } from "../config/global-constants";

@Injectable({ providedIn: "root" })

export class GraphService implements OnInit {
  graph: ForceGraphInstance;
  gData = { nodes: [], links: [] }
  colorBy: string = 'BU'
  graphData: any = 0;
  document = [];
  operation = [];
  equipment = [];
  @Output() selected = new EventEmitter<{}>();

  constructor(private http: HttpClient) { }

  ngOnInit(): void {

    this.getGraphData(this.document, this.operation, this.equipment);
  }

  getGraphData(document: string[], operation: string[], equipment: string[]) {
    const queryParams = `?standard=${document}&operation=${operation}&equipment=${equipment}`
    return this.http
      .get<{ gData: { nodes: [], links: [] } }>(GlobalConstants.api_redirect_uri + '/requirements/api/graph' + queryParams)
  }

  onGraph(colorBy: string, data: any) {
    const divWidth = document.getElementById("graph").offsetWidth;
    let selectedNodes = new Set();
    this.graph = ForceGraph()
      (document.getElementById('graph'))
      .graphData(data)
      .nodeId('id')
      .nodeVal('value')
      .nodeLabel('id')
      .linkSource('source')
      .linkTarget('target')
      .nodeColor('#2050ee')
      .nodeAutoColorBy(colorBy)
      .linkWidth(3)
      .linkColor('#005e00')
      .width(divWidth)
      .nodeRelSize(3)
      .nodeCanvasObjectMode(() => 'after')
      .nodeCanvasObject((node, ctx, globalScale) => {
        const label = `${node.id}`;
        const fontSize = 14 / globalScale;
        ctx.font = `${fontSize}px Sans-Serif`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'gray';
        ctx.fillText(label, node.x, node.y + 5)
      })
      .linkDirectionalArrowLength(3)
      .linkDirectionalArrowColor(node => node ? 'purple' : 'blue')
      .onNodeClick((node, event) => {
        const untoggle = selectedNodes.has(node) && selectedNodes.size === 1;
        selectedNodes.clear();
        !untoggle && selectedNodes.add(node);
        this.graph.nodeColor(node => selectedNodes.has(node) ? 'darkorange' : '#2050ee'); // update color of selected nodes
        this.selected.emit(node);
      })

    return this.graph
  }

  coloredByGraph(item) {
    //nodeAutoColor only applies to nodes that doesn't have color assigned, therefore we need to remove the color for each node from the node array first
    for (let node of this.gData.nodes) {
      delete node.color;
    }
    this.colorBy = item;
  }
}
