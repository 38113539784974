import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
 
@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ConfirmationDialog {
    @Input() message: string = "Are you sure want to delete?";
    @Input() confirmButtonText = "Yes";
    @Input() cancelButtonText = "Cancel";
    @Input() showDialog: boolean;
    @Output() confirmEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
 
    onCancelClick(): void {
        this.confirmEvent.emit(false);
        this.showDialog = false;
    }

    onConfirmClick(): void {
        this.confirmEvent.emit(true);
        this.showDialog = false;
    }
}