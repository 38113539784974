<div class="tree__control-panel">
    <app-dropdown-menu title="Domain" [options]="domain" [multi]="true"></app-dropdown-menu>
    <app-dropdown-menu title="Standard" [options]="standards" [multi]="true"></app-dropdown-menu>
    <app-dropdown-menu title="BU" [options]="businessunits" [multi]="true"></app-dropdown-menu>
</div>
<div class="message--nodata" *ngIf="message.length > 0">
    <p>{{message}}</p>
</div>
<div class="tree__doc">
    <ngx-spinner name="sp-tree" bdColor="transperant" size="medium" color="#fff" type="square-jelly-box"
        [fullScreen]="false">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
    <ng-container
        *ngFor="let item of items  | paginate: { itemsPerPage: documentPerPage, currentPage: currentPage, id: 'first', totalItems: total }">
        <div class="document" *ngIf="item.selected == 'true'">
            <div class="document__expand" [ngClass]="{'expanded': expandDocument}"
                (click)="toggleExpand(item.document)">
                <svg class='icon-basic'>
                    <use xlink:href="/app/assets/icons/sprite.svg#expand"></use>
                </svg>
            </div>
            <app-standard [item]="item"></app-standard>
        </div>
    </ng-container>
    <div class="workitem__container">
        <ng-container *ngIf="expandDocument">
            <ng-container
                *ngFor="let workitem of workitems | paginate: { itemsPerPage: workitemPerPage, currentPage: currentworkItemPage, id: 'second', totalItems: totalParentWorkitems}; let i = index">
                <app-workitem [workitems]="workitems" [workitem]="workitem" (selected)="selectedWorkitem($event)"
                    [index]="i" [expandWorkitem]="expandWorkitem" [children]="children"></app-workitem>
                <ng-container *ngIf="expandWorkitem[i]">
                    <div class="mgl-2rem" *ngFor="let child of children[i]">
                        <app-workitem [workitem]="child" (selected)="selectedWorkitem($event, i)"></app-workitem>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
<div class="tree__pagination">
    <div>
        <p class="txt__title__md">
            <span class="txt__value__lg mgl-1rem txt__metric">2</span> Standards
            <span class="txt__value__lg mgl-1rem txt__metric">809</span> Work-items
        </p>
    </div>
    <div *ngIf="!expandDocument">
        <pagination-controls class="pagination-controls txt-lg" id="first"
            (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
    <div *ngIf="expandDocument">
        <pagination-controls class="pagination-controls txt-lg" id="second"
            (pageChange)="workItemsPageChanged($event)"></pagination-controls>
    </div>
</div>