<ng-container *ngIf="exist">
    <div  class="requirement__details">
            
        <div class="requirement--action">
            <button class="btn btn__md btn__modify">Modify</button>
            <button class="btn btn__md btn__approve">Approve</button>
            <button class="btn btn__md btn__delete">Delete</button>
        </div>
        <hr class="divider">
        <div class="requirement__content">
            <div class="requirement__title">
                <div class="txt__title__lg">{{language}}</div>
                <div class="txt__title__lg">{{nodeId}}</div>
                <div class="txt__title__lg">{{status}}</div>
            </div>
            <p class="txt__content mgt-2rem">{{content | textshorten:100}}</p>
        </div>
        <div class="requirement__properties">
            <div class="requirement__properties__document">
                <svg class='icon-basic'>
                    <use xlink:href="/app/assets/icons/sprite.svg#standard"></use>
                </svg>
                <h3 class=" mgl-1rem txt__title__lg">{{standard}}</h3>
            </div>
            <div class="requirement__properties__details">
                <ng-container>
                    <div class="data__attribute__container">
                        <span class="txt__title__md">FIT</span>
                    </div>
                    <div class="data__attribute__container">
                        <span class="txt__title__md">LOT</span>
                    </div>
                    <div class="data__attribute__container">
                        <span class="txt__title__md">Dynamic LOT</span>
                    </div>
                    <div class="data__attribute__container">
                        <span class="txt__title__md">Casing</span>
                    </div>
                    <div class="data__attribute__container">
                        <span class="txt__title__md">Test</span>
                    </div>
                    <div class="data__attribute__container">
                        <span class="txt__title__md">Cementing Unit</span>
                    </div>
                </ng-container>
            </div>
        </div>
  
        <app-flowchart class="requirement__flowchart" [flowChart]="flowchart"></app-flowchart>
        <div class="requirement__messages">
            <span class="txt__title__sm">Messages:</span>
            <div class="requirement__messages__contianer">
                <span class="txt__title__sm">No pending issues</span>
            </div>
        </div>
    </div>
</ng-container>
<div *ngIf="!exist" class="message--nodata">
    <p>{{message}}</p>
</div>

<ngx-spinner name="sp-reqdetilas" bdColor="transperant" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="false">
    <p style="color: white"> Loading... </p>
</ngx-spinner>